.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: calc(1.5 * var(--unit));
}

.grid > * {
  margin-bottom: calc(2 * var(--line-height-sp));
}

@media (max-width: 812px) {

  .grid > * {
    grid-column-start: 1 !important;
    grid-column-end: span 1 !important;
  }
}

@media (min-width: 812px) {

  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .grid * {
    grid-column-start: auto;
    grid-column-end: span 5;
  }

    .gird *:last-child {
      margin-bottom: 0;
    }
}

.gridItem--landscape {
  grid-column-end: span 8;
}

.gridItem--span-2 {
  grid-column-end: span 2;
}

.gridItem--span-3 {
  grid-column-end: span 3;
}

.gridItem--span-4 {
  grid-column-end: span 4;
}

.gridItem--span-6 {
  grid-column-end: span 6;
}

.gridItem--span-7 {
  grid-column-end: span 7;
}

.gridItem--span-8 {
  grid-column-end: span 8;
}

.gridItem--span-9 {
  grid-column-end: span 9;
}

.gridItem--span-10 {
  grid-column-end: span 10;
}

.gridItem--span-11 {
  grid-column-end: span 11;
}

.gridItem--start {
  grid-column-start: 1;
}

.grid--page {
  display: grid;
  grid-column-gap: var(--unit);
}

.grid--pageAreaB {
  padding-right: var(--unit);
}

@media (min-width: 1200px) {

  .grid--page {
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
    "a a a a a a a a a a a a a a a"
    "b b b b b b b b b b b b c c c"
    "d d d d d d d d d d d d d d d";
  }

  .grid--page .grid--pageAreaA {
    grid-area: a;
  }

  .grid--page .grid--pageAreaB {
    grid-area: b;
    padding-right: 0;
  }

  .grid--page .grid--pageAreaC {
    grid-area: c;
  }

  .grid--page .grid--pageAreaD {
    grid-area: d;
  }
}
