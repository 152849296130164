footer {
  margin-top: calc(2 * var(--unit));
  margin-bottom: calc(.25 * var(--unit));
}

footer > *:last-child {
  margin-bottom: 0;
}

footer p {
  max-width: 100%;
}
