nav {
  margin-bottom: calc(1 * var(--unit));
}

nav h1 {
  display: inline;
}

.navCollection {
  display: inline;
}

.navCollection a {
  color: blue;
}

nav, .navCollection {
}

nav > *,
.navCollection > * {
  display: inline;
  margin-right: calc(.4 * var(--unit));
}

nav > *:last-child,
.navCollection > *:last-child {
  margin-right: 0;
}

.navPrevNext {
}

.navPrevNext * {
  display: inline;
}

.navPrevNext > * {
  margin-right: calc(.5 * var(--unit));
}

.navPrevNext:last-child {
  margin-right: 0;
}
