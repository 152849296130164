@font-face {
  font-family: "BBB-Herthey-Futural-15";
  src: url("../fonts/BBB-Herthey-Futural-15.otf") format("opentype");
}

@font-face {
  font-family: "BBB-Herthey-Futural-30";
  src: url("../fonts/BBB-Herthey-Futural-30.otf") format("opentype");
}

@font-face {
  font-family: "BBB-Herthey-Futural-90";
  src:url("../fonts/BBB-Herthey-Futural-90.otf") format("opentype");
}

html {
  font-size: 16px;
  line-height: var(--line-height);
  font-family: "BBB-Herthey-Futural-30", sans-serif;
}

h1, h2, h3, h4, h5, h6, ul, p {
  font-size: 1em;
  line-height: var(--line-height);
  font-weight: normal;
  margin: 0;
  margin-bottom: var(--line-height-sp);
}

ul, ol {
  padding: 0
}

ul {
  list-style-type: none;
}

ul li {
  position: relative;
  padding-left: var(--unit);
}

ul li:before {
  content: "–";
  position: absolute;
  top: 0;
  left: 0;
}

ul.--subtile li {
  padding-left: 0;
}

ul.--subtile li:before {
  content: "";
  display: none;
}

a, a:visited, a:hover {
  color: inherit;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

a.--subtile,
.subtile-link-children a {
  text-decoration: none;
}

a.--subtile:hover,
.subtile-link-children a:hover {
  text-decoration: underline;
}

a.--subtile.--active,
a.--active,
.subtile-link-children a.--active {
  text-decoration: underline;
}

p {
  max-width: 60rem;
}

.indent {
  padding-left: calc(.25 * var(--unit));
  padding-right: calc(.25 * var(--unit));
}

@media (min-width: 812px) {
  .indent {
    padding-left: calc(.75 * var(--unit));
    padding-right: calc(.75 * var(--unit));
  }
}

.text--secondary {
  font-size: .85rem;
}

span.bot-proof > span {
  display: none;
}
