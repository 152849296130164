body {
  padding: calc(.25 * var(--unit));
}

ul.list--subtile {
  list-style-type: none;
  padding: 0;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

.flex > * {
  margin-right: var(--unit);
}

.flex > *:last-child {
  margin-right: 0;
}

.flexItem--break:before {
  content: "";
  width: 100%;
}

