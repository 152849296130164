figure {
  margin: 0;
  margin-bottom: calc(1.5 * var(--unit));
  max-width: 100%;
}

figure figcaption {
  display: block;
  margin-top: calc(.25 * var(--line-height-sp));
}

img {
  display: block;
  max-width: 100%;
}
